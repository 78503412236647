.tt-content::after {
	position: absolute;
	content: '';
	right: 100%;
	width: 0;
	height: 0;
	top: 50%;
	transform: translateY(-50%);
	border-top: 8px solid transparent;
	border-bottom: 8px solid transparent;
	border-right: 8px solid #e5e5e5;
}
