.flatpickr-day.selected {
	@apply bg-cyan;
	border: 0;
}

.flatpickr-calendar {
	border-radius: 0;
}

@media (min-width: 640px) {
	.flatpickr-input {
		display: none;
	}
}
