.choices__item {
	font-size: 1rem !important;
	padding-right: 2rem;
}

.choices {
	margin-bottom: 0 !important;
	cursor: pointer;
}

.choices[data-type*='select-one'] select.choices__input,
.choices[data-type*='select-multiple'] select.choices__input {
	display: block !important;
	opacity: 0;
	pointer-events: none;
	position: absolute;
	left: 0;
	bottom: 0;
}

.choices[data-type*='select-multiple'] .choices__inner,
.choices[data-type*='text'] .choices__inner {
	cursor: pointer !important;
	margin-bottom: 0;
}
.choices[data-type*='select-multiple'] .choices__inner input {
	cursor: pointer;
}
.choices[data-type*='select-multiple'] .choices__inner input:focus {
	cursor: text;
}

input[type='search'].choices__input {
	max-width: 100%;
}
input[type='search'].choices__input:focus {
	box-shadow: none;
}

.choices__list--dropdown {
	z-index: 9999 !important;
	word-break: normal !important;
}

@media (min-width: 640px) {
	.choices__list--dropdown .choices__item--selectable {
		padding-right: 60px;
	}
}

.choices__list--single {
	padding: 2px 4px 0;
}
