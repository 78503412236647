/* Checkbox */

.checkbox input[type="checkbox"] {
  opacity: 0;
}

.checkbox label {
  position: relative;
  display: inline-block;
}

.checkbox label::before {
  position: relative;
  content: "";
  margin-right: 1em;
  vertical-align: top;

  /*Needed for the line-height to take effect*/
  display: inline-block;
}

.checkbox label::after {
  position: absolute;
  content: "";

  /*Needed for the line-height to take effect*/
  display: inline-block;
}

/*Outer box of the fake checkbox*/
.checkbox label::before{
  height: 24px;
  width: 24px;

  border: 1px solid #b4b4b4;
  background: #f4f4f4;
}

/*Checkmark of the fake checkbox*/
.checkbox label::after {
  height: 8px;
  width: 16px;
  border-left: 3px solid;
  border-bottom: 3px solid;
  @apply border-blue-800;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  left: 4px;
  top: 6px;
}

/*Hide the checkmark by default*/
.checkbox input[type="checkbox"] + label::after {
  content: none;
}

/*Unhide on the checked state*/
.checkbox input[type="checkbox"]:checked + label::after {
  content: "";
}

/*Adding focus styles on the outer-box of the fake checkbox*/
.checkbox input[type="checkbox"]:focus + label::before {
  outline: rgb(59, 153, 252) auto 5px;
}
