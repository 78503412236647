@font-face {
	font-family: 'Roboto';
	src: url('../assets/fonts/Roboto-Bold.woff2') format('woff2'),
		url('../assets/fonts/Roboto-Bold.woff') format('woff');
	font-weight: 600;
	font-style: normal;
}

@font-face {
	font-family: 'Roboto';
	src: url('../assets/fonts/Roboto-Regular.woff2') format('woff2'),
		url('../assets/fonts/Roboto-Regular.woff') format('woff');
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: 'Karmilla';
	src: url('../assets/fonts/karmilla-regular-016-webfont.woff2') format('woff2'),
		url('../assets/fonts/karmilla-regular-016-webfont.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}
