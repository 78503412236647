
@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 0.5;
  }
}

.fadeIn {
  animation: fadeIn .3s forwards;
}

@keyframes dialogFadeIn {
  0% {
    background-color: rgba(34, 42, 58, 0);
  }

  100% {
    background-color: rgba(34, 42, 58, 0.5);
  }
}

.dialogFadeIn {
  animation: dialogFadeIn .3s forwards;
}

@keyframes fadeOut {
  0% {
    opacity: 0.5;
  }

  100% {
    opacity: 0;
  }
}

.fadeOut {
  animation: fadeOut .3s forwards;
}

@keyframes dialogFadeOut {
  0% {
    background-color: rgba(34, 42, 58, 0.5);
  }

  100% {
    background-color: rgba(34, 42, 58, 0);
  }
}

.dialogFadeOut {
  animation: dialogFadeOut .3s forwards;
}

@keyframes slideInUp {
  0% {
    opacity: 0;
    transform: translateY(50%);
  }

  100% {
    opacity: 1;
    transform: none;
  }
}

.slideInUp {
  animation: slideInUp .2s forwards;
}

@keyframes slideOutDown {
  0% {
    opacity: 1;
    transform: translateY(0);
  }

  100% {
    opacity: 0;
    transform: translateY(50%);
  }
}

.slideOutDown {
  animation: slideOutDown .2s forwards;
}

@keyframes rotate {
  from{
    transform: rotate(360deg);
  }
  to{
    transform: rotate(0deg);
  }
}

.rotate {
  animation: rotate 2s linear infinite;
}

@keyframes slideInLeft {
  0% {
    transform: translateX(200%);
  }


  100% {
    transform: translateX(0);
  }
}

.slideInLeft {
  animation: slideInLeft .5s forwards;
}

@keyframes slideOutRight {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(100%);
  }
}

.slideOutRight {
  animation: slideOutRight .2s forwards;
}

.delay-1s {
  animation-delay: 1s;
}

@keyframes shrinkHeight {
  0% {
  }
  80% {
  }

  100% {
    height: 0;
    margin: 0;
  }
}

.shrinkHeight{
  animation: shrinkHeight .2s forwards;
}

.activity-initial {
  transition: transform .2s ease-in;
  transform: translateX(22rem);
}

.activity-slide-in {
  transition: transform .2s ease-in;
  transform: translateX(0) !important;
}

.delegate-input, .delegate-venue {
  box-shadow: none;
  transition: box-shadow 0.2s ease-in, background 0.2s ease-in;
  position: relative;
}

.delegate-input.active, .delegate-venue.active {
  box-shadow: 0px 0px 15px -3px rgba(0, 0, 0, 0.2), 0 0px 6px -2px rgba(0, 0, 0, 0.1);
  z-index: 20;
  background: white;
}

.overlay {
  transition: background 0.2s ease-in;
}


