.no-focus-outline *:focus {
	@apply outline-none;
	@apply shadow-none;
}

input,
textarea,
button,
select,
div,
a {
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.shadow-top,
.shadow-bottom {
	box-shadow: 0 0px 4px rgba(0, 0, 0, 0.5);
}

.absolute-center {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.mh-12 {
	min-height: 3rem;
}

.overflow-y-overlay {
	overflow-y: overlay !important;
}

.bg-cyan {
	background: #34d0da;
}

.bg-cyan-50 {
	background: rgba(52, 208, 218, 0.5);
}

.text-cyan {
	color: #34d0da;
}

.focus\:shadow-solid:focus {
	box-shadow: 0 0 0 2px currentColor;
}
