@import './fonts.css';
@import './tailwind.css';
@import './animations.css';
@import './utils.css';
@import './checkbox.css';
@import './tooltip.css';
@import './radio.css';
/* purgecss start ignore */
@import 'choices.js/public/assets/styles/choices.css';
@import 'flatpickr/dist/flatpickr.css';
@import './choices.css';
@import './flatpickr.css';
/* purgecss end ignore */

.w-96 {
	width: 24rem;
}

.w-128 {
	width: 32rem;
}

.w-192 {
	width: 48rem;
}

.h-max-content {
	height: max-content;
}

.ql-editor {
	min-height: 10rem;
}

.ql-editor p {
	margin-bottom: 0.75rem;
	font-size: 0.875rem;
}
.ql-editor table {
	width: 100%;
	max-width: 558px;
	table-layout: fixed;
}
.ql-editor table td {
	padding: 2px 4px;
}
.ql-editor table tr:nth-child(odd) td {
	background-color: #efefef;
}
.ql-editor table tr:nth-child(even) td {
	background-color: #f9f9f9;
}

.choices__list--multiple .choices__item {
	font-size: 0.75rem !important;
	padding: 2px 6px;
}

.ql-html-overlayContainer {
	background: #0000007d;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 9999;
}

.ql-html-popupContainer {
	background: #ddd;
	position: absolute;
	top: 5%;
	left: 5%;
	right: 5%;
	bottom: 5%;
	border-radius: 0 !important;
}

.ql-html-textContainer {
	position: relative;
	height: calc(100% - 40px);
	padding: 20px;
	box-sizing: content-box;
}

.ql-html-textArea {
	background: #fff;
	position: absolute;
	left: 15px;
	width: calc(100% - 30px);
	height: calc(100% - 60px) !important;
}

.ql-html-textArea .ql-syntax {
	word-break: break-all;
	white-space: pre-wrap;
}

.ql-html-buttonCancel {
	margin-right: 20px;
}

.ql-html-buttonCancel,
.ql-html-buttonOk {
	@apply bg-blue-500;
	@apply text-sm;
	@apply py-1;
	@apply px-3;
	@apply text-white;
	@apply font-normal;
}
.ql-html-buttonCancel {
	@apply bg-orange-500;
}

.ql-html-popupTitle {
	margin: 0;
	display: block;
	font-style: italic;
}

.ql-html-buttonGroup {
	position: absolute;
	bottom: 20px;
	transform: scale(1) !important;
	left: calc(50% - 60px);
}

.underline-links a {
	text-decoration: underline;
}
