.radio input[type="radio"] {
  position: absolute;
  opacity: 0;
}
.radio input[type="radio"] + .radio-label:before {
  content: '';
  background: #f4f4f4;
  border-radius: 100%;
  border: 1px solid #b4b4b4;
  display: inline-block;
  width: 24px;
  height: 24px;
  position: relative;
  margin-right: 1em;
  vertical-align: top;
  cursor: pointer;
  text-align: center;
}

.d-radio input[type="radio"] + .radio-label:before {
  content: '';
  background: #ffffff;
  border: 2px solid #eeeeee;
  display: inline-block;
  width: 15px;
  height: 15px;
  position: relative;
  margin-right: 1em;
  vertical-align: middle;
  cursor: pointer;
  text-align: center;
  transition: background 0.2s ease-in;

  /* TODO: Animate the options coming in, going out */
}
.radio.d-radio input[type="radio"]:checked + .radio-label:before {
  @apply bg-cyan;
  box-shadow: none !important;
}

.radio input[type="radio"]:checked + .radio-label:before {
  @apply bg-blue-800;
  box-shadow: inset 0 0 0 4px #f4f4f4;
}
.radio input[type="radio"]:focus + .radio-label:before {
  outline: none;
  border-color: #3197EE;
}
.radio input[type="radio"]:disabled + .radio-label:before {
  box-shadow: inset 0 0 0 4px #f4f4f4;
  border-color: #b4b4b4;
  background: #b4b4b4;
}
.radio input[type="radio"] + .radio-label:empty:before {
  margin-right: 0;
}
